<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="logo" class="my-3" contain height="200" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-card hover class="mx-auto" max-width="400">
          <v-img class="white--text align-end" :src="fourxperweek"> </v-img>
          <v-card-text class="text--primary text-center">
            <ContactButton title="Sign Me Up" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card hover class="mx-auto" max-width="400">
          <v-img class="white--text align-end" :src="threexperweek"> </v-img>
          <v-card-text class="text--primary text-center">
            <ContactButton title="Sign Me Up" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card hover class="mx-auto" max-width="400">
          <v-img class="white--text align-end" :src="twoxperweek"> </v-img>
          <v-card-text class="text--primary text-center">
            <ContactButton title="Sign Me Up" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h2>Our Programs</h2>
        <p>
          Each of our athletes recieve a strength and conditioning program based
          off their individual:
        </p>
        <ul>
          <li>Goals</li>
          <li>Athletic Season</li>
          <li>Age and Experience Level</li>
        </ul>
      </v-col>
      <v-col cols="12" md="6">
        <h2>Training Environment</h2>
        <p>The training environment at BAS is:</p>
        <ol>
          <li>Fun</li>
          <li>Safe</li>
          <li>Competitive</li>
        </ol>
        <p class="mt-2">
          We teach our athletes how to have a growth mindset, be a leader in the
          weight room, and continually push themselves to perform at their best.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h2>Training Sessions</h2>

        <p>
          There are 4 training sessions per day and each session is 45-60
          minutes long.
        </p>

        <ul>
          <li>3:30 - 4:30pm</li>

          <li>4:30 - 5:30pm</li>

          <li>5:30 - 6:30pm</li>

          <li>6:30 - 7:30pm</li>
        </ul>
      </v-col>
      <v-col cols="12" md="6">
        <h2>Try the 1st Session Free</h2>
        <p>Click below to schedule your free session!</p>

        <ContactButton title="Free Session" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import logo from '@/assets/images/5Logo.svg'
import fourxperweek from '@/assets/images/4xperweek.svg'
import threexperweek from '@/assets/images/3xperweek.svg'
import twoxperweek from '@/assets/images/2xperweek.svg'

export default {
  components: { ContactButton },
  name: 'StrengthandConditioning',

  data() {
    return {
      logo: logo,
      fourxperweek: fourxperweek,
      threexperweek: threexperweek,
      twoxperweek: twoxperweek,
    }
  },
}
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>
